<template>
  <ModalWrapper
    :title="$t('components.welcome_video.title')"
    @close="$emit('close')"
  >
    <div class="flex flex-col gap-8">
      <YoutubeVideo id="dPQ1cmwK2Fc" />

      <i18n-t
        tag="div"
        keypath="components.welcome_video.info"
      >
        <BaseLink
          to="//www.zaslat.cz"
          target="_blank"
        >
          {{ $t('brand.name_tld') }}
        </BaseLink>
      </i18n-t>

      <div class="flex gap-4">
        <BaseButton
          v-if="showGuide"
          size="md"
          @click="$emit('startGuide')"
        >
          {{ $t('components.welcome_video.button_start_guide') }}
        </BaseButton>
        <BaseButton
          size="md"
          color="alternative"
          @click="$emit('close')"
        >
          {{ $t('components.welcome_video.button_skip_guide') }}
        </BaseButton>
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup lang="ts">
defineEmits<{
  (e: 'startGuide'): void
  (e: 'close'): void
}>()

defineProps<{
  showGuide?: boolean
}>()

const welcomeVideoCookie = useCookie('welcome-video', { maxAge: 1704085200 })
welcomeVideoCookie.value = '1'
</script>
